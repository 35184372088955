
export const ecosystem = [
    {
        'title': 'Stasha Smartchain',
        'body': 'Stasha Smartchain is a new generation high-performant blockchain based on a unique consensus algorithm (S-DPoS).',
        'eco': '../images/eco1.png',
    },
    {
        'title': 'Stasha Exchanges',
        'body': 'This is a flexible ecosystem which gives users access to all crypto products and services in a single platform.',
        'eco': '../images/eco2.svg',
    },
    {
        'title': 'Stasha Block Explorer',
        'body': 'This is a flexible ecosystem which gives users access to all crypto products and services in a single platform.',
        'eco': '../images/eco3.svg',
    },
    {
        'title': 'Stasha Wallet',
        'body': 'Experience the freedom of managing your digital assets and explore a world of decentralized finance.',
        'eco': '../images/eco4.svg',
    },
    {
        'title': 'Alterverse',
        'body': "Alterverse is Stasha's art infrastructure which aims at bridging NFT and making it more user-centric.",
        'eco': '../images/eco5.svg',
    },
    {
        'title': 'NFT Marketplace',
        'body': 'Immerse yourself in the world of Stasha NFT Marketplace and join the revolution of digital ownership.',
        'eco': '../images/eco6.svg',
    },
    {
        'title': 'Staking Platform',
        'body': 'Join the Stasha Staking Platform and maximize the value of your holdings.',
        'eco': '../images/eco7.svg',
    },
    {
        'title': 'Multi-sig Wallet',
        'body': 'Suited for companies that have embraced decentralization and need to own asset vaults managed by various signatories.',
        'eco': '../images/eco8.svg',
    },
]

export const faq = [
    {
        'title': 'What is Stasha Blockchain Ecosystem?',
        'body': "Stasha is a leading blockchain infrastructure and solutions providing ecosystem. Stasha is an ecosystem of blockchain-intrinsic products and services with the sole aim of permeating all areas of the globe with its influence in the blockchain ecosystem.\n Stasha aims to be at the fore-front of mass crypto adoption in Africa and ultimately the globe by leasing out to the crypto market various products that would appeal to the average crypto enthusiast.",
    },
    {
        'title': 'Is Stasha Blockchain EVM compatible?',
        'body': 'Yes, Stasha Blockchain is EVM (Ethereum Virtual Machine) compatible. This means that developers can deploy and run smart contracts written in Solidity, the programming language of Ethereum, on the Stasha blockchain. The EVM compatibility allows for easy migration of existing Ethereum-based decentralized applications (dApps) to the Stasha platform, fostering interoperability and expanding the range of applications that can be built and executed on the Stasha blockchain. This compatibility ensures that developers and users can leverage their existing knowledge and experience with Ethereum while exploring the innovative features and capabilities of the Stasha ecosystem.',
    },
    {
        'title': 'What is Stasha Coin (STC)?',
        'body': 'Stasha Coin (STC) is the native utility token of the Stasha ecosystem. It serves as the primary means of value transfer and governance within the platform. STC is built on the Binance Smart Chain (BSC), leveraging its fast and low-cost transactions. Holders of STC can participate in various activities within the ecosystem, including staking, yield farming, voting on governance proposals, and accessing exclusive features and benefits.',
    },
    {
        'title': 'Has Stasha been Audited?',
        'body': 'Yes, Stasha has been audited by reputable blockchain security firms, including DefiMoon and Certik. These audits are conducted to ensure the security and integrity of the Stasha ecosystem, including its smart contracts, protocols, and overall infrastructure. The audits help identify potential vulnerabilities and ensure that the platform is robust and secure for users and investors. By undergoing thorough audits, Stasha demonstrates its commitment to transparency and building a safe and reliable platform for its community.',
    },
]

export const faq_ambassador = [
    {
        'title': 'What is Stasha Ambassaborship program?',
        'body': "The Stasha Ambassador program enables you to be an advocate for Stasha in your local community, actively contributing to the larger vision of the project. You are empowered to take the lead in organizing virtual and in-person meetups, generating original content, expanding your network, and engaging in various other activities that support the growth of Stasha Ecosystem.",
    },
    {
        'title': 'Do I get paid for this program?',
        'body': 'This program gives Ambassadors the ability to grow their professional skills with a world-class community. Ambassadors will also have the opportunity to be rewarded with NFTs, swag, and more as they rank up.',
    },
    {
        'title': 'How can I be a part in this program?',
        'body': 'Very simple, Just click the apply button from the program list',
    },
    {
        'title': 'How can I get Stasha BrandKits?',
        'body': 'To obtain Stasha BrandKits, apply and get selected while the team handles the rest. The BrandKits typically include various assets such as logos, color palettes, typography guidelines, and other design elements that can be used for promotional purposes, marketing materials, and community engagement. By using the Stasha BrandKits, you can ensure consistent and cohesive branding when representing our project.',
    },
]


export const real_ecosystem = [
    {
        'title': 'Stasha Smartchain',
        'body': 'Stasha Smartchain is a new generation high-performant blockchain based on a unique consensus algorithm (S-DPoS).',
        'eco': '../images/eco1.png',
    },
    {
        'title': 'Stasha Exchanges',
        'body': 'This is a flexible ecosystem which gives users access to all crypto products and services in a single platform.',
        'eco': '../images/eco2.svg',
    },
    {
        'title': 'Stasha Block Explorer',
        'body': 'This is a flexible ecosystem which gives users access to all crypto products and services in a single platform.',
        'eco': '../images/eco3.svg',
    },
    {
        'title': 'Stasha Wallet',
        'body': 'Experience the freedom of managing your digital assets and explore a world of decentralized finance.',
        'eco': '../images/eco4.svg',
    },
    {
        'title': 'Alterverse',
        'body': "Alterverse is Stasha's art infrastructure which aims at bridging NFT and making it more user-centric.",
        'eco': '../images/eco5.svg',
    },
    {
        'title': 'NFT Marketplace',
        'body': 'Immerse yourself in the world of Stasha NFT Marketplace and join the revolution of digital ownership.',
        'eco': '../images/eco6.svg',
    },
    {
        'title': 'Staking Platform',
        'body': 'Join the Stasha Staking Platform and maximize the value of your holdings.',
        'eco': '../images/eco7.svg',
    },
    {
        'title': 'Multi-sig Wallet',
        'body': 'Suited for companies that have embraced decentralization and need to own asset vaults managed by various signatories.',
        'eco': '../images/eco8.svg',
    },
    {
        'title': 'Stasha Coin',
        'body': 'Stasha Coin is a revolutionary digital currency built on the robust foundations of Binance Smartchain.',
        'eco': '../images/eco9.svg',
    },
    {
        'title': 'Stasha Pay',
        'body': 'Enjoy bordeless payments with Stasha Pay. Our solutionis user friendly, fast and secure.',
        'eco': '',
    },
    {
        'title': 'Stasha Academy',
        'body': 'Stasha Crypto Academy is your gateway to mastering the world of cryptocurrencies and blockchain technology.',
        'eco': '../images/eco11.svg',
    },
    {
        'title': 'Stasha Dictionary',
        'body': 'Discover the language of the crypto world with the Stasha Crypto Dictionary. ',
        'eco': '../images/eco12.svg',
    },
]
